<template>
  <div class="testimonials-seconde">
    <img class="cover" src="@/assets/img/home-page/testimonials.png" alt="">
    <div class="row w-100 align-items-center">
      <div class="col-md-7 right">
        <swiper class="swiper" 
     :navigation="{
      enabled: true,
      nextEl: '.Next',
      prevEl: '.Prev',
    }" :autoplay="{
      delay: 3000,
    }" 
    :speed="1000" 
    :breakpoints="swiperOptions.breakpoints"
    :slidesPerView="2"
    :centeredSlides="true" 
    :spaceBetween="20">
      <swiper-slide class="item" v-for="(item, index) in Testimonials" :key="index">
        <div class="date">5 / 5 / 2024</div>
        <p> {{ item.text }}</p>
        <div class="user">
          <img src="@/assets/img/home-page/Customer-icon.png" alt="">
          <div class="name">{{ item.name }}</div>
        </div>
      </swiper-slide>
    </swiper>
      </div>
      <div class="col-md-5 left">
        <h1>
          {{ 
          this.$i18n.locale == 'ar' ?
          `اكتشف ماذا قالوا عنا وانضم لعائلتنا المميزة!`
          :
          `Find out what they said about us and join our special family!`  
          }}
        </h1>
        <p>
          {{ 
          this.$i18n.locale == 'ar' ?
          `صوت عملائنا ينطق بالجودة والاحترافية`
          :
          `The voice of our customers speaks of quality and professionalism`  
          }}
        </p>
    <!-- Custom navigation buttons -->
       
        <span class="myPrev Prev mx-2">
          <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="36" y="36.7412" width="36" height="36" rx="5" transform="rotate(180 36 36.7412)" fill="white"/>
            <path d="M22.3204 19.4483L18.0707 23.7413L19.0605 24.7412L25 18.7412L24.5051 18.2413L19.0605 12.7412L18.0707 13.7411L22.3204 18.0341L11 18.0341L11 19.4483L22.3204 19.4483Z" fill="#0A3041"/>
          </svg> 
          <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="1.24023" width="35" height="35" rx="4.5" stroke="white"/>
          <path d="M13.6796 18.0331L17.9293 13.7401L16.9395 12.7402L11 18.7402L11.4949 19.2402L16.9395 24.7402L17.9293 23.7404L13.6796 19.4474H25V18.0331H13.6796Z" fill="white"/>
        </svg>
        </span>
        <span class="myNext Next">
          <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="36" y="36.7412" width="36" height="36" rx="5" transform="rotate(180 36 36.7412)" fill="white"/>
            <path d="M22.3204 19.4483L18.0707 23.7413L19.0605 24.7412L25 18.7412L24.5051 18.2413L19.0605 12.7412L18.0707 13.7411L22.3204 18.0341L11 18.0341L11 19.4483L22.3204 19.4483Z" fill="#0A3041"/>
          </svg> 
          <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="1.24023" width="35" height="35" rx="4.5" stroke="white"/>
          <path d="M13.6796 18.0331L17.9293 13.7401L16.9395 12.7402L11 18.7402L11.4949 19.2402L16.9395 24.7402L17.9293 23.7404L13.6796 19.4474H25V18.0331H13.6796Z" fill="white"/>
        </svg>
          
        </span>
 
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dirlang:'',
      swiperOptions: {
        breakpoints: {
          300: { slidesPerView: 1, spaceBetween: 50 },
          576: { slidesPerView: 1, spaceBetween: 50 },
          767: { slidesPerView: 1, spaceBetween: 50 },
          991: { slidesPerView: 2, spaceBetween: 50 },
          1200: { slidesPerView: 2, spaceBetween: 50 },
        }
      }
    }
  },
  computed:{
    Testimonials(){
            return this.$store.state.Testimonials
        }
    },
  components: {  
  },
    mounted(){
        this.$store.dispatch('GetTestimonials');
      }

};
</script>
