<template>
    <div class="companies required-item consulting">
        <div class="container">
            <h2 class="title-profile mt-0">{{ $route.name }}</h2>
            <p>{{ $t('TextExample') }}</p>
            <div class="row">
               <div class="head-section-profile justify-content-start">
                <div class="search ">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13.92 13.92L17 17M16.111 8.581C16.111 12.768 12.728 16.162 8.556 16.162C4.383 16.162 1 12.768 1 8.582C1 4.393 4.383 1 8.555 1C12.728 1 16.111 4.394 16.111 8.581Z"
                            stroke="#225476" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                    <input type="text" placeholder="ابحث باسم الاستشارة...">
                </div>
                <div>
                    <select name="" id="" class="mb-0">
                        <option value="">{{ this.$i18n.locale == 'ar' ? 'التصنيف حسب...':'Filter By...' }}</option>
                    </select>
                </div>
               </div>
               <div class="col-md-4" v-for="item in 6" :key="item">
                            <div class="card-role " style="box-shadow: 0px 5px 30px 0px rgba(10, 48, 65, 0.10); ">
                                <div class="d-flex align-items-center">
                                    <div class="icon">
                                        <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.25 5.75C14.25 7.14239 13.6969 8.47774 12.7123 9.46231C11.7277 10.4469 10.3924 11 9 11C7.60761 11 6.27226 10.4469 5.28769 9.46231C4.30312 8.47774 3.75 7.14239 3.75 5.75C3.75 4.35761 4.30312 3.02226 5.28769 2.03769C6.27226 1.05312 7.60761 0.5 9 0.5C10.3924 0.5 11.7277 1.05312 12.7123 2.03769C13.6969 3.02226 14.25 4.35761 14.25 5.75ZM12.75 5.75C12.75 4.75544 12.3549 3.80161 11.6517 3.09835C10.9484 2.39509 9.99456 2 9 2C8.00544 2 7.05161 2.39509 6.34835 3.09835C5.64509 3.80161 5.25 4.75544 5.25 5.75C5.25 6.74456 5.64509 7.69839 6.34835 8.40165C7.05161 9.10491 8.00544 9.5 9 9.5C9.99456 9.5 10.9484 9.10491 11.6517 8.40165C12.3549 7.69839 12.75 6.74456 12.75 5.75ZM2.625 12.5C1.92881 12.5 1.26113 12.7766 0.768845 13.2688C0.276562 13.7611 0 14.4288 0 15.125V15.5C0 17.2947 1.14225 18.8127 2.76375 19.8447C4.39425 20.8827 6.6015 21.5 9 21.5C11.3985 21.5 13.605 20.8827 15.2363 19.8447C16.8578 18.8127 18 17.2947 18 15.5V15.125C18 14.4288 17.7234 13.7611 17.2312 13.2688C16.7389 12.7766 16.0712 12.5 15.375 12.5H2.625ZM1.5 15.125C1.5 14.8266 1.61853 14.5405 1.8295 14.3295C2.04048 14.1185 2.32663 14 2.625 14H15.375C15.6734 14 15.9595 14.1185 16.1705 14.3295C16.3815 14.5405 16.5 14.8266 16.5 15.125V15.5C16.5 16.6048 15.795 17.7118 14.4307 18.5795C13.0755 19.442 11.1578 20 9 20C6.84225 20 4.9245 19.442 3.56925 18.5795C2.20425 17.7125 1.5 16.604 1.5 15.5V15.125Z" fill="#225476"/>
                                        </svg>
                                    </div>
                                    <div>
                                        <div class="name">مثال لاسم الموظف</div>
                                        <div class="role-name">مثال لاسم الدور</div>
                                    </div>
                                </div>
                                <div class="powers">
                                    {{ $t('The Powers') }}
                                </div>
                                <div class="power-list">
                                    <span>الوئاثق</span>
                                    <span>الاستشارت</span>
                                    <span>اشتراكاتي</span>
                                    <span>الخدمات المطلوب</span>
                                </div>
                                <div class="power-count">
                                    <div class="count">4  {{ $t('Powers') }}</div>
                                    <button class="btn-main b-20" @click="togglePowerUpdate">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.61111 0.5C6.76687 0.500173 6.91669 0.559815 7.02995 0.666741C7.14321 0.773666 7.21137 0.919805 7.22049 1.0753C7.22962 1.23079 7.17903 1.3839 7.07906 1.50334C6.97909 1.62279 6.83728 1.69955 6.68261 1.71794L6.61111 1.72222H1.72222V10.2778H10.2778V5.38889C10.278 5.23313 10.3376 5.08331 10.4445 4.97005C10.5514 4.85679 10.6976 4.78863 10.8531 4.77951C11.0086 4.77038 11.1617 4.82097 11.2811 4.92094C11.4006 5.02091 11.4773 5.16272 11.4957 5.31739L11.5 5.38889V10.2778C11.5001 10.5861 11.3836 10.8831 11.174 11.1092C10.9643 11.3353 10.6769 11.4738 10.3694 11.4969L10.2778 11.5H1.72222C1.41387 11.5001 1.11688 11.3836 0.890776 11.174C0.664677 10.9643 0.526182 10.6769 0.503056 10.3694L0.5 10.2778V1.72222C0.499902 1.41387 0.61636 1.11688 0.826026 0.890776C1.03569 0.664676 1.32307 0.526182 1.63056 0.503056L1.72222 0.5H6.61111ZM10.4263 0.709611C10.5363 0.60001 10.6838 0.536377 10.839 0.531638C10.9942 0.526899 11.1454 0.581409 11.2618 0.684096C11.3783 0.786784 11.4513 0.929948 11.466 1.08451C11.4807 1.23908 11.4361 1.39345 11.3411 1.51628L11.2904 1.57433L5.24039 7.62372C5.13041 7.73332 4.98285 7.79696 4.82765 7.80169C4.67246 7.80643 4.52129 7.75192 4.40483 7.64924C4.28837 7.54655 4.21537 7.40339 4.20065 7.24882C4.18592 7.09426 4.23058 6.93988 4.32556 6.81706L4.37628 6.75961L10.4263 0.709611Z" fill="white"/>
                                        </svg>
                                        <span>{{ $t('Edit') }}</span>
                                    </button>
                                </div>
                            </div>
                </div>
                <!-- <div class="col-12 mt-5">
                    <div class="pagination">
                        <svg @click="prevPage" :class="[index == 1 ? 'disabled':'']"
                        class="prev " width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.404541 6.87983C0.145501 6.64436 0 6.32515 0 5.99234C0 5.65953 0.145501 5.34033 0.404541 5.10485L5.62038 0.367661C5.87988 0.132173 6.23178 -7.8473e-05 6.59868 3.49332e-08C6.78034 3.89081e-05 6.96023 0.0325644 7.12805 0.0957197C7.29587 0.158875 7.44835 0.251423 7.57678 0.36808C7.70521 0.484737 7.80707 0.623218 7.87656 0.775616C7.94604 0.928015 7.98178 1.09135 7.98174 1.25629C7.98169 1.42122 7.94587 1.58454 7.87631 1.73691C7.80674 1.88928 7.70481 2.02772 7.57632 2.14432L3.33891 5.99234L7.57724 9.84037C7.70942 9.95617 7.81487 10.0947 7.88744 10.2479C7.96002 10.4011 7.99826 10.5659 7.99994 10.7326C8.00162 10.8994 7.96671 11.0648 7.89724 11.2191C7.82777 11.3735 7.72513 11.5138 7.59531 11.6317C7.4655 11.7497 7.3111 11.843 7.14113 11.9063C6.97117 11.9695 6.78904 12.0013 6.60537 12C6.4217 11.9986 6.24017 11.964 6.07137 11.8983C5.90258 11.8325 5.74989 11.7369 5.62222 11.617L0.402697 6.87983H0.404541Z" fill="white"/>
                        </svg> 
                        <span class="current">
                            {{index}}
                        </span>
                        <span style="margin: 0 5px;">/</span>
                        <span class="pageLength">{{ pageLength }}</span>
                        <svg @click="nextPage" :class="[index == pageLength ? 'disabled':'']"
                        class="next" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.59546 5.12017C7.8545 5.35564 8 5.67485 8 6.00766C8 6.34047 7.8545 6.65967 7.59546 6.89515L2.37962 11.6323C2.12012 11.8678 1.76822 12.0001 1.40132 12C1.21966 12 1.03977 11.9674 0.87195 11.9043C0.704126 11.8411 0.551648 11.7486 0.423219 11.6319C0.29479 11.5153 0.192926 11.3768 0.123444 11.2244C0.0539621 11.072 0.0182218 10.9087 0.0182647 10.7437C0.0183076 10.5788 0.0541328 10.4155 0.123694 10.2631C0.193255 10.1107 0.29519 9.97229 0.42368 9.85568L4.66109 6.00766L0.422756 2.15963C0.290581 2.04384 0.18513 1.9053 0.112556 1.75211C0.0399819 1.59892 0.00173953 1.43414 5.72359e-05 1.26738C-0.00162506 1.10063 0.0332885 0.935241 0.10276 0.780869C0.172232 0.626497 0.27487 0.486232 0.404687 0.368259C0.534504 0.250286 0.688899 0.156967 0.858864 0.0937468C1.02883 0.0305268 1.21096 -0.00132692 1.39463 4.34928e-05C1.5783 0.00141295 1.75983 0.0359789 1.92863 0.101725C2.09742 0.167471 2.25011 0.263081 2.37777 0.382974L7.5973 5.12017L7.59546 5.12017Z" fill="#909090"/>
                        </svg> 
                    </div>
                </div> -->
            </div>
        </div>
        <PowerUpdate  v-if="ShowPowerUpdate" @hide="togglePowerUpdate" />
    </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
    data() {
        return {
            ShowPowerUpdate:false,
            ServiceOrders:[],
            // index:1,
            // pageLength:5
        }
    },
    components:{
        PowerUpdate:defineAsyncComponent( () => import(/* webpackChunkName: "App" */'@/components/Profile/Companies/Modals/PowerUpdate.vue') ),
    },
    methods:{
        togglePowerUpdate(){
        this.ShowPowerUpdate = !this.ShowPowerUpdate
    },
        // prevPage(){
        //     if(this.index != 1){
        //         this.index--
        //     }
        // },
        // nextPage(){
        //     if(this.index != this.pageLength){
        //         this.index++
        //     }
        // }
      
    },
    mounted(){
        // this.$store.dispatch('ServiceOrders').then(r => {
        //     this.ServiceOrders = this.$store.state.ServiceOrders;
        //     console.log(this.ServiceOrders)
        // });
    }
};
</script>