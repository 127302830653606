<template>
    <NewConsulting v-if="this.$store.state.consulting == true"  />

  <div class="consulting required-item">
    <div class="container">
      <h2 class="title-profile mt-0">{{ $route.name }}</h2>


      <div class="col-12">
        <ul class="nav nav-pills" id="pills-tab3" role="tablist">
          <li class="nav-item active" role="presentation">
            <button data-index="Packages1" class="nav-link py-3 active" id="v-pills-Packages1-tab" data-bs-toggle="pill"
              data-bs-target="#v-pills-Packages1" type="button" role="tab" aria-controls="v-pills-Packages1"
              aria-selected="true">
              <span class=" fs-sm-4">{{ $t('Consulting') }}</span>
            </button>
          </li>
          <li class="nav-item " role="presentation">
            <button data-index="Packages2" class="nav-link py-3 " id="v-pills-Packages2-tab" data-bs-toggle="pill"
              data-bs-target="#v-pills-Packages2" type="button" role="tab" aria-controls="v-pills-Packages2"
              aria-selected="true">
              <span class=" fs-sm-4">{{ $t('Balance') }}</span>
            </button>
          </li>
        </ul>
        <div class="tab-content mt-3" id="pills-tabContent">
          <div class="tab-pane fade show active" id="v-pills-Packages1" role="tabpanel"
            aria-labelledby="v-pills-Packages1-tab">
            <div class="row">
              <div class="head-section-profile">
                <div class="search">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.92 13.92L17 17M16.111 8.581C16.111 12.768 12.728 16.162 8.556 16.162C4.383 16.162 1 12.768 1 8.582C1 4.393 4.383 1 8.555 1C12.728 1 16.111 4.394 16.111 8.581Z"
                      stroke="#225476" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <input type="text" placeholder="ابحث باسم الاستشارة...">
                </div>
                <div>
                  <button @click="this.$store.state.consulting = true" class="btn-main">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.73684 14.7368C6.73684 15.0719 6.86992 15.3931 7.10681 15.63C7.3437 15.8669 7.66499 16 8 16C8.33501 16 8.6563 15.8669 8.89319 15.63C9.13008 15.3931 9.26316 15.0719 9.26316 14.7368V9.26316H14.7368C15.0719 9.26316 15.3931 9.13008 15.63 8.89319C15.8669 8.6563 16 8.33501 16 8C16 7.66499 15.8669 7.3437 15.63 7.10681C15.3931 6.86992 15.0719 6.73684 14.7368 6.73684H9.26316V1.26316C9.26316 0.928148 9.13008 0.606858 8.89319 0.36997C8.6563 0.133082 8.33501 0 8 0C7.66499 0 7.3437 0.133082 7.10681 0.36997C6.86992 0.606858 6.73684 0.928148 6.73684 1.26316V6.73684H1.26316C0.928148 6.73684 0.606858 6.86992 0.36997 7.10681C0.133082 7.3437 0 7.66499 0 8C0 8.33501 0.133082 8.6563 0.36997 8.89319C0.606858 9.13008 0.928148 9.26316 1.26316 9.26316H6.73684V14.7368Z"
                        fill="white" />
                    </svg>
                    استشارة جديدة
                  </button>
                </div>
              </div>
              <div class="consulting-tabel">
                <div class="row head-tabel">
                  <div class="col-md-2">
                    {{ $t('Reference Number') }}
                  </div>
                  <div class="col-md-2">
                    {{ $t('Consultation Details') }}
                  </div>
                  <div class="col-md-2">
                    {{ $t('Status') }}
                  </div>
                  <div class="col-md-2">
                    {{ $t('Employee Name') }}
                  </div>
                  <div class="col-md-2">
                    {{ $t('How Apply') }}
                  </div>
                  <div class="col-md-2">
                    {{ $t('Consultation Date') }}
                  </div>
                </div>
                <div class="row body-tabel"
                 v-for="item in Consultation" :key="item.id"
                 v-if="Consultation.length > 0">
                  <div class="col-md-2">
                    {{ item.id }}
                  </div>
                  <div class="col-md-2">
                    {{ item.description }}
                  </div>
                  <div class="col-md-2">
                    {{ item.status }}
                  </div>
                  <div class="col-md-2">
                    {{ item.employee }}
                  </div>
                  <div class="col-md-2">
                    {{ item.type }}
                  </div>
                  <div class="col-md-2">
                    {{ item.id }}
                  </div>
                </div>
                <NoDataToShow v-else />
              </div>
            </div>
          </div>
          <div class="tab-pane fade show " id="v-pills-Packages2" role="tabpanel"
            aria-labelledby="v-pills-Packages2-tab">
            <div class="cons-block d-flex justify-content-between mb-4 mt-4">
              <div class="d-flex ">

                <div style="padding: 17px 10px 0px 10px;">
                  <p class="box" style="font-size:20px; font-weight: 700;">
                    {{
                      this.$i18n.locale == 'ar' ?
                        'رصيد الاستشارات'
                        :
                    'You can postpone payment for the service until a later time by pressing the button and moving to the next step'
                    }}
                  </p>

                </div>
              </div>
              <div class="d-flex ">

                <div style="padding: 17px 10px 0px 10px; margin-right: 50px;">
                  <span class="span-title" >
                    {{
                      this.$i18n.locale == 'ar' ?
                        'رصيد الاستشارات'
                        :
                    'You can postpone payment for the service until a later time by pressing the button and moving to the next step'
                    }}
                  </span>
                  <p class="text-center span-value1">18 استشارة</p>

                </div>
                <div style="padding: 17px 10px 0px 10px; margin-right: 50px;">
                  <span class="span-title" >
                    {{
                      this.$i18n.locale == 'ar' ?
                        'رصيد الاستشارات'
                        :
                    'You can postpone payment for the service until a later time by pressing the button and moving to the next step'
                    }}
                  </span>
                  <p class="text-center span-value2">20 استشارة</p>
                </div>
                <div style="padding: 17px 10px 0px 10px; margin-right: 50px;">
                  <span class="span-title" >
                    {{
                      this.$i18n.locale == 'ar' ?
                        'رصيد الاستشارات'
                        :
                    'You can postpone payment for the service until a later time by pressing the button and moving to the next step'
                    }}
                  </span>
                  <p class="text-center span-value3">2 استشارة</p>
                </div>
              </div>
            </div>
            <p class="span-title">يمكنك طلب شحن رصيد الاستشارات الخاص بك</p>
            <div class="cons-block d-flex justify-content-between mb-4 mt-4">
              <div class="d-flex " style="width: 400px;">

                <div style="padding: 17px 10px 0px 10px; width: 100%;">
                  <select name="city" >
                    <option value="اسم المدينة">ادخل رقم الاستشارات المطلوب...</option>
                  </select>

                </div>
              </div>
              <div class="d-flex">
                <button class="btn-main">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.73684 14.7368C6.73684 15.0719 6.86992 15.3931 7.10681 15.63C7.3437 15.8669 7.66499 16 8 16C8.33501 16 8.6563 15.8669 8.89319 15.63C9.13008 15.3931 9.26316 15.0719 9.26316 14.7368V9.26316H14.7368C15.0719 9.26316 15.3931 9.13008 15.63 8.89319C15.8669 8.6563 16 8.33501 16 8C16 7.66499 15.8669 7.3437 15.63 7.10681C15.3931 6.86992 15.0719 6.73684 14.7368 6.73684H9.26316V1.26316C9.26316 0.928148 9.13008 0.606858 8.89319 0.36997C8.6563 0.133082 8.33501 0 8 0C7.66499 0 7.3437 0.133082 7.10681 0.36997C6.86992 0.606858 6.73684 0.928148 6.73684 1.26316V6.73684H1.26316C0.928148 6.73684 0.606858 6.86992 0.36997 7.10681C0.133082 7.3437 0 7.66499 0 8C0 8.33501 0.133082 8.6563 0.36997 8.89319C0.606858 9.13008 0.928148 9.26316 1.26316 9.26316H6.73684V14.7368Z"
                        fill="white" />
                    </svg>
                    تقديم الطلب
                  </button>

              </div>

            </div>
          </div>

        </div>
      </div>


    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import Cookie from 'cookie-universal';
const cookie = Cookie();
import { notify } from "@kyvg/vue3-notification";
import axios from 'axios';
export default {
  data() {
    return {
      Consultation:[],
    }
  },
  components:{
    NewConsulting:defineAsyncComponent( () => import(/* webpackChunkName: "App" */'@/components/Profile/modals/NewConsulting.vue') ),
    NoDataToShow: defineAsyncComponent(() => import(/* webpackChunkName: "App" */'@/components/Global/NoDataToShow.vue')),
  },
  methods: {
    GetConsulting(){
      axios.get(`/company/dashboard/consultations`, {
                        headers: {
                            "Authorization": `Bearer ${cookie.get('CompanyToken')}`
                        }
                    })
                    .then((res) => {
                        this.Consultation = res.data.data.Consultation	;
                    })
                    .catch(function (error) {
                        notify({
                            type: "error",
                            text: error.response.data.message,
                        });
                    });
    }

  },
    mounted(){
        this.GetConsulting();
    }
};
</script>
