<template>
    <div class="overlay open"></div>
    <div class="ask-ticket-card">
        <div class="close" @click="close">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.6581 1.33776L6.99582 6L11.6581 10.6622C11.79 10.7941 11.8641 10.973 11.8641 11.1595C11.8641 11.3461 11.79 11.525 11.6581 11.6569C11.5262 11.7887 11.3473 11.8628 11.1608 11.8628C10.9742 11.8628 10.7953 11.7887 10.6635 11.6569L6.00121 6.99461L1.33897 11.6569C1.20707 11.7887 1.02819 11.8628 0.841661 11.8628C0.655135 11.8628 0.476248 11.7887 0.344354 11.6569C0.21246 11.525 0.138364 11.3461 0.138364 11.1595C0.138364 10.973 0.212461 10.7941 0.344355 10.6622L5.0066 6L0.344355 1.33776C0.212461 1.20586 0.138364 1.02698 0.138364 0.840451C0.138364 0.653925 0.21246 0.47504 0.344354 0.343146C0.476248 0.211252 0.655134 0.137155 0.841661 0.137154C1.02819 0.137155 1.20707 0.211252 1.33897 0.343145L6.00121 5.00539L10.6635 0.343146C10.7953 0.211252 10.9742 0.137155 11.1608 0.137155C11.3473 0.137155 11.5262 0.211252 11.6581 0.343146C11.79 0.47504 11.8641 0.653925 11.8641 0.840452C11.8641 1.02698 11.79 1.20586 11.6581 1.33776Z"
                    fill="#0A3041" />
            </svg>
        </div>
        <div class="title">
            <svg width="41" height="46" viewBox="0 0 41 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.19986 45.3265C9.00348 45.3414 8.80625 45.3414 8.60987 45.3265C6.48707 44.9751 4.53239 43.9538 3.03177 42.4118C1.53114 40.8698 0.563321 38.888 0.269871 36.7565C0.208346 36.2053 0.178293 35.6511 0.179868 35.0965V10.9465C0.165106 8.45577 1.05864 6.0451 2.69318 4.16574C4.32772 2.28639 6.5912 1.06721 9.05986 0.736454C9.451 0.684694 9.84536 0.6613 10.2399 0.666454H23.2399C23.5545 0.652717 23.868 0.713488 24.1547 0.843818C24.4414 0.974148 24.6933 1.17035 24.8899 1.41645C25.0551 1.66022 25.1587 1.94044 25.1918 2.23305C25.2249 2.52567 25.1865 2.82195 25.0799 3.09645C24.9492 3.48227 24.6954 3.81451 24.3576 4.04213C24.0197 4.26976 23.6165 4.38019 23.2099 4.35645H10.4499C9.57482 4.32705 8.70308 4.47807 7.88891 4.80011C7.07475 5.12214 6.33558 5.60831 5.71742 6.22835C5.09926 6.84839 4.61533 7.58903 4.29576 8.40417C3.97619 9.21931 3.82781 10.0915 3.85987 10.9665V35.2165C3.83868 36.7662 4.37714 38.2717 5.37641 39.4565C6.37568 40.6413 7.76872 41.4259 9.29987 41.6665C9.6676 41.7169 10.0389 41.737 10.4099 41.7265H30.5799C31.4506 41.7544 32.3178 41.6031 33.1276 41.2821C33.9375 40.961 34.6727 40.477 35.2878 39.8601C35.9029 39.2431 36.3847 38.5064 36.7033 37.6956C37.0218 36.8847 37.1704 36.0171 37.1399 35.1465V17.7565C37.1125 17.5131 37.1378 17.2666 37.214 17.0339C37.2902 16.8011 37.4155 16.5874 37.5814 16.4072C37.7473 16.2271 37.95 16.0847 38.1758 15.9896C38.4015 15.8946 38.645 15.8492 38.8899 15.8565C39.0609 15.8592 39.2315 15.8759 39.3999 15.9065C39.7546 15.9916 40.0765 16.1792 40.3254 16.4459C40.5743 16.7127 40.7393 17.0467 40.7999 17.4065C40.8058 17.5714 40.7641 17.7345 40.6799 17.8765C40.6327 17.9481 40.6051 18.0308 40.5999 18.1165C40.5999 22.7831 40.5999 27.4265 40.5999 32.0465V34.7265C40.9417 36.2883 40.6886 37.9215 39.8899 39.3065C39.1509 41.057 37.9085 42.5486 36.3204 43.592C34.7322 44.6353 32.87 45.1833 30.9699 45.1665C28.2399 45.1665 25.4999 45.2265 22.7599 45.2265H10.0599C9.77505 45.2741 9.48802 45.3075 9.19986 45.3265Z"
                    fill="#225476" />
                <path
                    d="M32.7797 16.6658C32.3737 16.6554 31.9823 16.5118 31.6661 16.257C31.3498 16.0021 31.1262 15.6503 31.0297 15.2558C31.0153 15.056 31.0153 14.8555 31.0297 14.6558V13.8058C31.0297 12.8058 31.0297 11.8058 31.0297 10.8058C31.0297 10.6158 31.0297 10.5658 31.0297 10.5558C31.0297 10.5458 30.9597 10.5558 30.7897 10.5558H26.7997C26.5156 10.5627 26.2335 10.5062 25.974 10.3903C25.7145 10.2744 25.4841 10.102 25.2997 9.88577C25.105 9.66678 24.9639 9.40552 24.8876 9.12263C24.8112 8.83975 24.8017 8.54298 24.8597 8.25577C24.9148 7.83843 25.1282 7.45824 25.4558 7.19391C25.7834 6.92958 26.2002 6.80135 26.6197 6.83577H30.6897C30.8897 6.83577 30.9497 6.83577 30.9697 6.83577C30.9897 6.83577 30.9697 6.75577 30.9697 6.55577C30.9697 5.20577 30.9697 3.82577 30.9697 2.60577C30.9477 2.34975 30.9811 2.092 31.0676 1.85003C31.1541 1.60807 31.2917 1.38756 31.471 1.20351C31.6503 1.01945 31.8672 0.876158 32.1068 0.783371C32.3464 0.690583 32.6032 0.650475 32.8597 0.665773H33.0297C33.2481 0.668356 33.4638 0.714274 33.6643 0.800865C33.8648 0.887456 34.0462 1.013 34.1978 1.1702C34.3494 1.32741 34.4683 1.51316 34.5476 1.71668C34.6269 1.92019 34.665 2.13742 34.6597 2.35577C34.6597 2.85577 34.6597 3.35577 34.6597 3.85577V5.12577C34.6597 5.62577 34.6597 6.12577 34.6597 6.60577C34.6418 6.67124 34.6418 6.7403 34.6597 6.80577C34.7222 6.82065 34.7872 6.82065 34.8497 6.80577H38.9097C39.2247 6.79985 39.5364 6.87155 39.8172 7.01456C40.098 7.15756 40.3392 7.36747 40.5197 7.62577C40.6834 7.87444 40.7835 8.15946 40.8114 8.45584C40.8393 8.75223 40.7941 9.05093 40.6797 9.32577C40.5358 9.68738 40.2837 9.99574 39.958 10.2087C39.6322 10.4217 39.2487 10.5289 38.8597 10.5158H34.9597C34.7497 10.5158 34.7097 10.5158 34.6897 10.5158C34.6697 10.5158 34.6897 10.6458 34.6897 10.8058C34.6897 12.1358 34.6897 13.4758 34.6897 14.8058C34.6598 15.2742 34.4662 15.7173 34.1426 16.0573C33.819 16.3973 33.3861 16.6127 32.9197 16.6658H32.7797Z"
                    fill="#225476" />
                <path
                    d="M12.3493 24.8758C12.0715 24.8887 11.7942 24.8408 11.5369 24.7355C11.2795 24.6301 11.0483 24.4698 10.8593 24.2658C10.6913 24.0389 10.5733 23.779 10.513 23.5033C10.4527 23.2275 10.4514 22.9421 10.5093 22.6658C10.5838 22.2993 10.7682 21.9642 11.038 21.7051C11.3077 21.4461 11.6501 21.2754 12.0193 21.2158C12.1764 21.2121 12.3316 21.2501 12.4693 21.3258C12.5432 21.3774 12.6295 21.4084 12.7193 21.4158H28.3093C28.405 21.4071 28.4975 21.3763 28.5793 21.3258C28.7109 21.2504 28.858 21.206 29.0093 21.1958H29.1993C29.5457 21.2714 29.8596 21.454 30.0965 21.7178C30.3334 21.9816 30.4813 22.3133 30.5193 22.6658C30.6031 23.0505 30.5619 23.4519 30.4019 23.8116C30.2418 24.1713 29.9711 24.4705 29.6293 24.6658C29.2867 24.8416 28.9039 24.9243 28.5193 24.9058L12.3493 24.8758Z"
                    fill="#225476" />
                <path
                    d="M12.3787 35.1548C12.0981 35.1688 11.8177 35.1234 11.5558 35.0218C11.2939 34.9201 11.0564 34.7644 10.8587 34.5648C10.6887 34.3502 10.566 34.102 10.4988 33.8366C10.4316 33.5712 10.4213 33.2945 10.4687 33.0248C10.5181 32.6623 10.678 32.3237 10.9268 32.0553C11.1755 31.7869 11.5009 31.6017 11.8587 31.5248C11.9417 31.5144 12.0257 31.5144 12.1087 31.5248C12.1566 31.5228 12.2043 31.5306 12.2491 31.5478C12.2938 31.5651 12.3345 31.5913 12.3687 31.6248C12.4032 31.6608 12.4454 31.6885 12.4922 31.7058C12.539 31.7232 12.589 31.7296 12.6387 31.7248H28.1387C28.3437 31.738 28.5482 31.6929 28.7287 31.5948C28.8251 31.5372 28.9365 31.5094 29.0487 31.5148C29.3386 31.5538 29.6139 31.6661 29.8484 31.8411C30.0828 32.016 30.2688 32.2479 30.3887 32.5148C30.5281 32.8039 30.5911 33.1238 30.5719 33.4441C30.5526 33.7645 30.4517 34.0746 30.2787 34.3448C30.095 34.613 29.8488 34.8324 29.5613 34.984C29.2738 35.1356 28.9537 35.2148 28.6287 35.2148H12.3787V35.1548Z"
                    fill="#225476" />
                <path
                    d="M16.5793 14.6053H12.4693C11.9087 14.6063 11.3701 14.3873 10.9693 13.9953C10.8039 13.8325 10.6734 13.6377 10.5857 13.4228C10.498 13.2079 10.455 12.9774 10.4593 12.7453C10.5043 12.2467 10.7348 11.7831 11.1051 11.4461C11.4754 11.1091 11.9586 10.9233 12.4593 10.9253H20.3393C20.706 10.9046 21.0706 10.9924 21.3878 11.1776C21.7049 11.3629 21.9605 11.6374 22.1226 11.967C22.2847 12.2965 22.3462 12.6665 22.2994 13.0308C22.2525 13.3951 22.0995 13.7375 21.8593 14.0153C21.6812 14.2014 21.4669 14.3488 21.2295 14.4486C20.9921 14.5484 20.7368 14.5983 20.4793 14.5953H18.8893L16.5793 14.6053Z"
                    fill="#225476" />
            </svg>
            <div>{{ $t('Request_new_ticket') }} </div>
        </div>
        <Form ref="myForm" @submit="AskTicket" :validation-schema="schema">
            <div class="ask-1 mt-3">
                <div class="title-ask-1">
                    {{ $t('ask-1') }}
                </div>
                <div class="d-flex align-items-center flex-wrap">
                    <div v-for="item in TicketReasons" :key="item.id">
                        <Field :value="item.id" :id="`reason-${item.id}`" type="radio" hidden name="reason_id" />
                        <label :for="`reason-${item.id}`">{{ item.name }}</label>
                    </div>
                </div>
                <ErrorMessage name="reason_id" class="error-message" />
            </div>
            <div class="ask-2 mt-3">
                <div class="title-ask-2">
                    {{ $t('Please_add_the_ticket_address') }}
                </div>
                <Field type="text" name="subject" :placeholder="$t('Ticket_address')" />
                <ErrorMessage name="subject" class="error-message" />
            </div>
            <div class="ask-2 mt-3">
                <div class="title-ask-2">
                    {{ $t('Ticket_details') }}
                </div>
                <Field as="textarea" type="text" name="description" :placeholder="$t('Ticket_details')" />
                <ErrorMessage name="description" class="error-message" />
            </div>
            <div class="footer-card">
                <button class="btn-third border-0" @click="ClearAll">{{ $t('Clear_all') }}</button>
                <button class="btn-main" type="submit" >{{ $t('Request_a_consultation') }}</button>
            </div>
        </Form>
       
    </div>
</template>
<script>
import { notify } from "@kyvg/vue3-notification";
import axios from 'axios';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { configure } from "vee-validate";
import Cookie from 'cookie-universal';
const cookie = Cookie();
export default {
    data() {
        return {
            TicketReasons:[]
        }
    },
    setup() {
        configure({
            validateOnInput: true,
        });

        const schema = yup.object({
            subject: yup.string().required(cookie.get('lang') == 'ar' ? 'حقل العنوان مطلوب' : 'subject required').min(4, cookie.get('lang') == 'ar' ? 'يجب أن يكون الاسم من 4 أحرف على الأقل' : 'name must be at least 4 characters'),

            description: yup.string().required(cookie.get('lang') == 'ar' ? 'حقل العنوان مطلوب' : 'subject required').min(6, cookie.get('lang') == 'ar' ? 'يجب أن يكون الاسم من 6 أحرف على الأقل' : 'name must be at least 6 characters'),
            reason_id: yup
                .string()
                .required(cookie.get('lang') === 'ar' ? 'يجب اختيار القسم' : 'Section is required'),
        });

        return {
            schema,

        }
    },
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    methods: {
        close() {
            this.$emit('hideTicket')
        },
        ClearAll() {
            this.$refs.myForm.resetForm();
        },
        
        GetTicketReasons(){
            axios.get(`/company/dashboard/ticket-reasons`, {
                        headers: {
                            "Authorization": `Bearer ${cookie.get('CompanyToken')}`
                        }
                    })
                    .then((res) => {
                        this.TicketReasons = res.data.data.TicketReason	;
                    })
                    .catch(function (error) {
                        notify({
                            type: "error",
                            text: error.response.data.message,
                        });
                    });
        },
        AskTicket(values) {
                const FData = new FormData();
                FData.append('subject', values.subject);
                FData.append('description', values.description);
                FData.append('company_id', cookie.get('Companydata').id);
                FData.append('reason_id', values.reason_id);
                FData.append('priority', 1);
                FData.append('status', 'open');
                // end point must be : /company/dashboard/tickets
                // but we set user for testing
                axios
                    .post(`/user/tickets`, FData, {
                        headers: {
                            "Authorization": `Bearer ${cookie.get('Token')}`
                        }
                    })
                    .then((res) => {
                        if (res.status == 200) {
                            notify({
                                type: "success",
                                text: this.$i18n.locale == 'ar' ? 'تم ارسال الطلب بنجاح' : 'Add Ticket Success',
                            });
                            window.location.reload();
                        }
                    })
                    .catch(function (error) {
                        notify({
                            type: "error",
                            text: error.response.data.message,
                        });
                    });

        },
    },
    mounted(){
        this.GetTicketReasons();
    }

};
</script>