<template>
       <div class="contact-services">
      <div class="row">
        <div class="col-md-4 mb-3">
          <h3>
            {{ $t("Don't hesitate") }}
          </h3>
          <h1>{{ $t('To contact us !') }}</h1>
          <p>
            {{ $t('contat-desc') }}
          </p>
        </div>
        <div class="col-md-8">
          <Form class="form-contact" @submit="handelForm" :validation-schema="schema">
          <div class="row">
            <div class="col-md-6 mb-2">
              <div class="field">
                <Field class="contact-field" name="name" type="text" :placeholder="$t('FullName')" />
                <ErrorMessage name="name" class="error-message" />
              </div>
            </div>
            <div class="col-md-6 mb-2">
              <div class="field">
                <Field class="contact-field" name="company" type="text" :placeholder="$t('CompanyName')" />
                <ErrorMessage name="company" class="error-message" />
              </div>
            </div>
            <div class="col-md-6 mb-2">
              <div class="field">
                <input id="phone" type="tel" name="phone">
                <p id="output" class="error-message mt-1 mb-1"></p>
              </div>
            </div>
            <div class="col-md-6 mb-2">
              <div class="field">
                <Field name="ReasonCommunication" v-slot="{field}">
                  <multiselect v-model="value" v-bind="field" :options=" Reasons " @select="PickId "
                  :placeholder=" this.$i18n.locale == 'ar' ? 'اختر سبب التواصل' : 'Choose the reason' " label="text" track-by="text" selectLabel="" 
                  :selectedLabel="this.$i18n.locale == 'ar' ? 'المحدد' : 'Selected'"
                  :deselectLabel="this.$i18n.locale == 'ar' ? 'اضغط للإزالة' : 'Press enter to remove'"></multiselect>
                </Field>
                <ErrorMessage name="ReasonCommunication" class="error-message" />
              </div>
            </div>
            <div class="col-12 mb-2">
              <Field class="contact-field" as="textarea" name="messege" :placeholder=" $t('messege') " />
              <ErrorMessage name="messege" class="error-message" />
              <div class="d-flex justify-content-end mt-3">
                <button class="btn-main" type="submit">{{ $t('Request_a_consultation') }}</button>
              </div>
            </div>
          </div>
        </Form>
        </div>
      </div>
     </div>
</template>
<script>
// import axios from 'axios';
// import { notify } from "@kyvg/vue3-notification";
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { configure } from "vee-validate";
import Multiselect from 'vue-multiselect';
import Cookie from 'cookie-universal'
const cookie = Cookie()
import intlTelInput from "../../../../intl-tel-input"
export default {
  setup() {
    configure({
      validateOnInput: true,
    });
    const schema = yup.object({
      name: yup.string().required(cookie.get('lang') == 'ar' ? 'حقل الاسم مطلوب' : 'Name required').min(4, cookie.get('lang') === 'ar' ? 'يجب أن يكون الاسم من 4 أحرف على الأقل' : 'name must be at least 4 characters'),
      ReasonCommunication: yup.object().required(cookie.get('lang') == 'ar' ? 'اختيار السبب مطلوب' : 'reason required'),
      company: yup.string().required(cookie.get('lang') == 'ar' ? 'اسم الشركة مطلوب' : 'company name required').min(4, cookie.get('lang') === 'ar' ? 'يجب أن يكون الاسم من 4 أحرف على الأقل' : 'name must be at least 4 characters'),
      messege: yup.string().required(cookie.get('lang') == 'ar' ? 'يجب كتابة الرسالة' : 'messege required').min(10, cookie.get('lang') === 'ar' ? 'يجب أن يكون من 10 أحرف على الأقل' : 'must be at least 10 characters'),
    });
    return {
      schema,
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  data() {
    return {
      Reasons: [],
      value: '',
      reason_value:'',
      PhoneNumber: '',

    };
  },
  methods:{
    PickId () {
      this.reason_value = this.value.id;
      console.log(this.reason_value)
    },
  },
  mounted() {
    this.$store.dispatch('GetContactReasons').then(r => {
      this.Reasons = this.$store.state.ContactReasons;
    });

    const output = document.querySelector("#output");
    const inputElement = document.querySelector('#phone');

    const iti = intlTelInput(inputElement, {
      i18n: {
        searchPlaceholder: cookie.get('lang') ? 'بحث' : 'Search',
      },
      initialCountry: "auto",
      nationalMode: true,
      geoIpLookup: callback => {
        fetch("https://ipapi.co/json")
          .then(res => res.json())
          .then(data => callback(data.country_code))
          .catch(() => callback("us"));
      },
      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.7.3/build/js/utils.js"
    });

    const handleChange = () => {
      let text;
      if (cookie.get('lang') == 'ar') {
        if (inputElement.value != '') {
          text = iti.isValidNumber()
            ? ""
            : "رقم غير صحيح - الرجاء اعد المحاولة";
          if (iti.isValidNumber()) {
            this.FullNumber = iti.getNumber();
          }
        } else {
          text = "الرجاء ادخال رقم صحيح";
        }
      }
      else {
        if (inputElement.value != '') {
          text = iti.isValidNumber()
            ? ""
            : "Invalid number - please try again";
          if (iti.isValidNumber()) {
            this.FullNumber = iti.getNumber();
          }
          else {
            this.FullNumber = false;
          }
        } else {
          text = "Please enter a valid number below";
        }
      }
      const textNode = document.createTextNode(text);
      output.innerHTML = "";
      output.appendChild(textNode);
    };

    // listen to "keyup", but also "change" to update when the user selects a country
    inputElement.addEventListener('change', handleChange);
    inputElement.addEventListener('keyup', handleChange);
  }

}
</script>