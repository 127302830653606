<template>
    <div class="support">
        <div class="container">
           <div class="d-flex align-items-center mb-5">
            <span class="back" @click="$router.go(-1)">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.62753 0.879974L11.1476 5.51537C11.2161 5.58561 11.2647 5.66169 11.2935 5.74363C11.3222 5.82557 11.3364 5.91336 11.3359 6.00701C11.3359 6.10065 11.3215 6.18844 11.2928 6.27038C11.264 6.35232 11.2156 6.42841 11.1476 6.49864L6.62753 11.134C6.50198 11.2628 6.34492 11.33 6.15635 11.3356C5.96779 11.3412 5.80525 11.274 5.66873 11.134C5.53176 11.0053 5.46031 10.8442 5.45437 10.6508C5.44844 10.4575 5.51418 10.2908 5.65161 10.1508L9.00741 6.70934L1.35413 6.70934C1.16009 6.70934 0.997322 6.64192 0.86583 6.50707C0.734337 6.37222 0.668819 6.20554 0.669275 6.00701C0.669275 5.80801 0.734794 5.64109 0.86583 5.50624C0.996866 5.3714 1.15963 5.30421 1.35413 5.30468L9.00741 5.30467L5.65161 1.86324C5.52605 1.73448 5.46031 1.5706 5.45437 1.37161C5.44844 1.17261 5.51418 1.00874 5.65161 0.879974C5.77717 0.739507 5.93697 0.669273 6.13101 0.669273C6.32505 0.669273 6.49056 0.739507 6.62753 0.879974Z" fill="#0A3041"/>
                </svg>
            </span>
            <h2 class="title">{{ $route.name }}</h2>
           </div>
            <div class="row">
                <div class="col-md-8">
                        <div class="correspondence mt-0" style="padding: 20px;">
                            <div v-if="joined" class="row mb-3" v-for="item in receive_message">
                               <div v-if="item.sent_by_user == 1">
                                <div class="col-md-5"></div>
                                <div class="col-md-7 d-flex mb-2">
                                    <div class="card-blue">
                                        <div class="content">
                                           {{ item.content }}
                                        </div>
                                        <div class="date">
                                            {{formatDate(item.created_at) }}
                                        </div>
                                    </div>
                                    <div class="logo">
                                        <img width="32" src="@/assets/img/Icon/provider.png" alt="">
                                    </div>
                                </div>
                               </div>
                                <div v-else>
                                    <div class="col-md-5">
                                    <div class="card-white">
                                        <div class="content">
                                            {{ item.content }}
                                        </div>
                                        <div class="date">
                                            {{formatDate(item.created_at) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-7"></div>
                                </div>
                            </div>
                            <div class="post-correspondence">
                                <input v-model="message" type="text" name="correspondence" placeholder="اكتب رسالة...">
                                <button class="btn-main" @click="SendMessage">
                                    <svg width="20" height="18" viewBox="0 0 20 18" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M0.0095238 17.5L20 9L0.0095238 0.5L0 7.11111L14.2857 9L0 10.8889L0.0095238 17.5Z"
                                            fill="white" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                </div>
                <div class="col-md-4 o-1">
                    <div class="ticket-info">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="status" :class="[StatusClass(TicketData.status)]"> {{  TicketData.status }} </div>
                            <div class="date"> {{ TicketData.created_at }}</div>
                        </div>
                        <h3 class="title-ticket">{{ TicketData.subject }}</h3>
                        <div class="section">{{  TicketData.reasonName }}</div>
                        <p class="desription">
                            {{  TicketData.description }} 
                        </p>
                        <div class="company">
                            {{  TicketData.companyName }} 
                        </div>
                        <div class="order_number">
                            {{ $t('order_number') }} :  {{  TicketData.id }} 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
import {io} from 'socket.io-client'
import Cookie from 'cookie-universal';
const cookie = Cookie();
const socket = io("https://etmaam.octopus-software.dev", {
            extraHeaders: {
            "token": `${cookie.get('Token')}`, // Include the token in the headers
            },
            });

export default {
    data() {
        return {
            TicketData: {},
            joined:false,
            message:'',
            receive_message:[]
        }
    },
    components: {
        AskTicket: defineAsyncComponent(() => import(/* webpackChunkName: "App" */'@/components/Profile/Support/AskTicket.vue')),
    },

    methods: {
        SendMessage(){
            // console.log(this.message)
            socket.emit("send_message", {
                content: this.message
            });
            this.message = '';
        },
        formatDate(dateString){
            const date = new Date(dateString);
            const year = date.getFullYear(); // Full year
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits for day

            return `${year}/${month}/${day}`;
        },
        StatusClass(status) {
            if (status == 'open' || status == 'under_review') {
                return 'review';
            }
            else if (status == 'answered') {
                return 'answered';
            }
            else if (status == 'pending') {
                return 'suspended';
            }
            else if (status == 'closed') {
                return 'solved';
            }
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const year = date.getFullYear(); // Full year
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits for day

            return `${year}/${month}/${day}`;
        },
    },
    created(){
        // Chat
        socket.emit("join_chat", {
            id: this.$route.params.id,
            type: "Ticket"
        });
       
    },
    mounted() {
        let TicketId = this.$route.params.id;
        this.$store.dispatch('GetTicketDetails',TicketId).then(r => {
            this.TicketData = this.$store.state.TicketDetails;
        });
        socket.on("logs", (data) => {
            // console.log("Logs:", data);
            if(data == 'You joined the chat.'){
                this.joined = true;
                socket.emit("load_messages");
            }
        });
        socket.on("load_messages", (messages) => {
            // console.log("Chat messages loaded:", messages);
            this.receive_message = messages;
        });

        socket.on("receive_message", (message) => {
            this.receive_message.push(message);
            // console.log("New message received:", message);
        });
       
    }
};
</script>